import {defineComponent, defineAsyncComponent, ref, inject} from 'vue';
import {getModule} from 'vuex-module-decorators';
import moment from 'moment';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import {APP_NAME, CONFIG_ENV, APP_CLASS} from '@/config';
import { FileUploadService } from 'v-file-upload';
import {humanReadableBytes} from '@/modules/Utils';
import Nomenclatoare from '@/store/Nomenclatoare';
import ExpeditiiDocument from '@/components/ExpeditiiDocument/ExpeditiiDocument.vue';
import ChangeTipDocument from '@/components/ChangeTipDocument/ChangeTipDocument.vue';
import {dateToStringDDMonYYYY, dateToStringDDMMYYYYHHMMSS, dateToStringDDMMYYYY, dateToStringYYYYMMDDStandard} from '@/modules/Utils';
import {ServiceDocument} from '@/modules/ServiceDocument';
import User from '@/store/User';
import {ServiceRepartizari} from '@/modules/ServiceRepartizari';
import Notify from 'quasar/src/plugins/Notify.js';import scroll from 'quasar/src/utils/scroll.js';;
import {denOrgUnitFromOrg} from '@/modules/Utils';
import {denPersoanaUtilizatorFromMarca} from '@/modules/Utils';
import {validateEmail, validateCNP, validateRomanianCIF} from '@/modules/Utils';
import IUser from '@/types/IUser';
import IDocument from '@/types/IDocument';
import INomCateg from '@/types/INomCateg';
import INomTipDoc from '@/types/INomTipDoc';
import INomRegistru from '@/types/INomRegistru';
import IScan from '@/types/IScan';
import IRepartizare from '@/types/IRepartizare';
import ITari from '@/types/ITari';
import IJudete from '@/types/IJudete';
import ILocalitati from '@/types/ILocalitati';
import { ServiceTari } from '@/modules/ServiceTari';
import { ServiceJudete } from '@/modules/ServiceJudete';
import { ServiceLocalitati } from '@/modules/ServiceLocalitati';
import { ServiceAdrese } from '@/modules/ServiceAdrese';
import IOrgUnit from '@/types/IOrgUnit';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import IAdrese from '@/types/IAdrese';
import IPortalMessage from '@/types/IPortalMessage';
import IChatMessages from '@/types/IChatMessages';
import IPersoana from '@/types/IPersoana';
import { ServiceArhive } from '@/modules/ServiceArhive';
import IArhiva from '@/types/IArhiva';

const { getScrollTarget, setVerticalScrollPosition /*setScrollPosition*/ } = scroll;

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IDocumentNull = OrNull<IDocument>;

interface IDocumentExtended extends IDocumentNull{
  den_org_init:string|null,
  adresa:string|null
}

const changeTipDocProxy = ref(null as any);
const myForm = ref({} as any);
const qDateProxy = ref(null);
const myFormCancellationDocument = ref({} as any);

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const TaskList = defineAsyncComponent({
  loader: () => import('@/components/Tasks/TaskList.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const RepartizatHistoric = defineAsyncComponent({
  loader: () => import('@/components/WorkOnDocument/RepartizatHistoric.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const EditCertificateUrbanism = defineAsyncComponent({
  loader: () => import('@/views/Documente/EditServiciiDeTransport.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const EditServiciiDeTransport = defineAsyncComponent({
  loader: () => import('@/views/Documente/EditServiciiDeTransport.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

export default defineComponent({
  name: 'WorkOnDocument',

  components: {
    DialogOpenScan, ExpeditiiDocument, ChangeTipDocument, TaskList, RepartizatHistoric, EditCertificateUrbanism, EditServiciiDeTransport
  },

  props: {
    appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    withFinalizeButton: {
      type: String,
      required: true,
      default: 'no',
      readonly: true
    },
    withRepartizareButton: {
      type: String,
      required: true,
      default: 'no',
      readonly: true
    },
    accesFrom: {
      type: String,
      required: true,
      default: 'no',
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    task: {
      type: Object,
      task_id: 0,
      task_type: '',
      action_on_step: {} as IActionOnStepTask
    }
  },

  setup() {    
    const emitter = inject("emitter") as any;
    return {
      emitter,      
      changeTipDocProxy,
      myForm,
      myFormCancellationDocument,
      qDateProxy
    };
  },

  data() {    
    return {        
      docApp: {
        appid: null,
        id_registru: null,
        id_categ: null,
        id_tip_document: null,
        id_doc: null,
        tip_request: null,
        data_doc: null,
        tip_solicitant: null,
        nume_denumire: null,
        cui: null,
        email_solicitant: null,
        titlu: null,
        descriere: null,
        nr_inreg: null,
        data_inreg: null,
        nr_file: null,
        este_confidential: null,
        ref_nr_doc: null,
        ref_data_doc: null,
        ref_id_registru: null,
        track_creation_date: null,
        id_org_expeditor: null,
        id_org_destinatar_init: null,
        id_org_destinatar_curent: null,
        finalizat_de_inregistrat: null,
        nu_se_repartizeaza:null,
        data_repartizare:null,
        este_repartizat:null,
        scans:[] as IScan[],
        repartizari:[],
        den_org_init:null,
        marca_user_deponent:null,
        sursa:null,
        status:null,
        termen_date: null,
        termen:null,
        termen_zi:null,
        id_tara: null,
        id_judet: null, 
        id_localitate: null, 
        strada: null,
        numar: null,
        cod_postal: null,
        adresa: null,
        track_creation_user: null,
        nume_prenume_reprezentant: null,
        nr_telefon_reprezentant: null,
        appid_referinta: null,
        text_for_search: null,
        flag: 0,
        description_cancellation: null,
        userid_cancellation: null,
        data_cancellation: null,
        tip_doc_flag: null,
        appid_document_extended: null
      } as IDocumentExtended,
      loadingDocumentData: false,
      loadingPdfForDownload: false,
      dialogUploadFile: false,
      loadingRepartizari: false,
      isRepartizat: false,
      isFinalizat: false,
      myLocale: CONFIG_ENV.myLocale,
      nuSeRepartizeaza: false,
      uploadingFileToServer: false,      
      dialogAddOrgUnitRepartizare: false,
      dialogAddUtilizatorRepartizare: false,
      showVisualEffectAddingOrgUnit: false,
      showVisualEffectAddingUtilizator: false, 
      uploadScanTitle: '',
      urlForUpload: CONFIG_ENV.URL_DOCUMENT_SCAN.uploadScan,
      auTrecut2SecundeDeLaUltimulSearch: false,
      headers: {},
      nomenclatoareStore: getModule(Nomenclatoare),
      filesSelectedForUpload: [] as any,
      //scans: [] as iScan[],
      repartizari: [] as IRepartizare[],
      optionsOrgUnit: [] as IOrgUnit[],
      optionsUtilizator: [] as IPersoana[],
      listaOrgUnitPentruRepartizare: [] as IOrgUnit[],
      listaUtilizatorPentruRepartizare: [] as IPersoana[],
      textSearchOrgUnit: '',
      textSearchUtilizator: '',
      userStore: getModule(User),
      tari: [] as ITari[],
      judete: [] as IJudete[],
      judeteNoFiltered: [] as IJudete[],
      localitati: [] as ILocalitati[],
      localitatiNoFiltered: [] as ILocalitati[],
      visibleDialogEditAdresa: false,
      origPopup_IdTara: 0 as number|null,
      origPopup_IdJudet: 0 as number|null,
      origPopup_IdLocalitate: 0 as number|null,
      origPopup_Strada: '' as string|null,
      origPopup_Numar: '' as string|null,
      origPopup_CodPostal: null as number|null,
      dialogHistoricRepartizare: false,
      adrese: [] as IAdrese[],
      adreseNoFiltered: [] as IAdrese[],
      isSuccess: false,
      inputAdresa: '',
      isSelectedAdresa: false,
      timerEnabled: true,
      timerCount: 5,
      dialogMessage: false,
      messageText: '',
      messageProgress: false,      
      portalMessages: [] as IPortalMessage[],
      chatMessages: [] as IChatMessages[],
      qrCode: '',
      dialogCancellationDocument: false,
      description_cancellation: '',
      visibleDialogEditReferintaExtern: false,
      ref_nr_doc: null as string|null,
      ref_data_doc: null as string|null,
      inputOrgUnit: '',
      isSelectedOrgUnit: false,
      orgUnits: [] as IOrgUnit[],
      orgUnitsNoFiltered: [] as IOrgUnit[],
      nume_denumire: null as string|null,
      visibleDialogEditFormCertificateUrbanism: false,
      visibleDialogEditFormServiciiDeTransport: false,      
      appid_documentextended: 0,    
      showPreviewFileCertificateUrbanism: false,
      showPreviewFileServiciiDeTransport: false,
      titleForPreviewForm: '',
      downloadingFile: false,
      urlBlobForPreviewFile: '',
      isPDFFileType: false,
      documentArhiva: {} as IArhiva|null
    }
  },

  methods: {
    resetDocument(){
      this.docApp = {
        appid: null,
        id_registru: null,
        id_categ: null,
        id_tip_document: null,
        id_doc: null,
        tip_request: null,
        data_doc: null,
        tip_solicitant: null,
        nume_denumire: null,
        cui: null,
        email_solicitant: null,
        titlu: null,
        descriere: null,
        nr_inreg: null,
        data_inreg: null,
        nr_file: null,
        este_confidential: null,
        finalizat_de_inregistrat: null,
        ref_nr_doc: null,
        ref_data_doc: null,
        ref_id_registru: null,
        track_creation_date: null,
        id_org_expeditor: null,
        nu_se_repartizeaza:null,
        data_repartizare:null,
        este_repartizat:null,
        id_org_destinatar_init: null,
        id_org_destinatar_curent: null,
        scans:[],
        repartizari:[],
        den_org_init:null,
        marca_user_deponent:null,
        sursa:null,
        status:null,
        termen_date: null,
        termen:null,        
        termen_zi:null,
        id_tara: null,
        id_judet: null, 
        id_localitate: null, 
        strada: null,
        numar: null,
        cod_postal: null,
        adresa: null,
        track_creation_user: null,
        nume_prenume_reprezentant: null,
        nr_telefon_reprezentant: null,
        appid_referinta: null,
        text_for_search: null,
        flag: 0,
        description_cancellation: null,
        userid_cancellation: null,
        data_cancellation: null,
        tip_doc_flag: null,
        appid_document_extended: null
      } as IDocumentExtended;
      this.nume_denumire = null as string|null;
    },

    fRenuntModificareTipDoc(){
      const vueInst = this;
      vueInst.changeTipDocProxy.hide();        
    },

    getDocument() {
      const vueInst=this;
      ServiceDocument.getDocument(vueInst.appid).then((presponse)=>{        
        if(presponse.status==='success'){
          vueInst.loadingDocumentData=false;
          vueInst.docApp=JSON.parse(JSON.stringify(presponse.document));
          if(vueInst.docApp.finalizat_de_inregistrat && vueInst.docApp.finalizat_de_inregistrat=='y'){
            vueInst.isFinalizat=true;
          }
          vueInst.isRepartizat=false; //inseamna ca repartizare infinit              
          vueInst.nuSeRepartizeaza=(vueInst.docApp.nu_se_repartizeaza && vueInst.docApp.nu_se_repartizeaza=='y') as boolean;
          vueInst.qrCode = vueInst.getLinkToQrCode(Number(vueInst.docApp.appid));
          vueInst.nume_denumire = vueInst.docApp.nume_denumire;
          
          vueInst.getScans();
          vueInst.getRepartizari();
          vueInst.getAdrese();
          
          vueInst.documentArhiva = null;
          if (vueInst.docApp.status === 'archived') {
            ServiceArhive.getArhiva(Number(vueInst.docApp.appid)).then((presponse)=>{
              vueInst.documentArhiva = presponse.arhiva as IArhiva;

              vueInst.documentArhiva.predat_data = vueInst.documentArhiva.predat_data == null ? '' : vueInst.displayData(vueInst.documentArhiva.predat_data);          
            });
          }
          vueInst.isSuccess = true;
        } else {
          if (this.$route.name === 'ViewDocument') {            
            this.$router.push({name: 'Dashboard', params: { param: 'documente_repartizate' }});
          }
        }
      });
    },

    fModificareTipDoc(id_categ:number, id_tip_doc:number){
      const vueInst = this;
      //console.log('fModificareTipDoc id_categ=%o si id_tip_doc=%o',id_categ, id_tip_doc)
      if(id_categ>0 && id_tip_doc>0){
        vueInst.docApp.id_categ = id_categ;
        vueInst.docApp.id_tip_document = id_tip_doc;
      }
      vueInst.changeTipDocProxy.hide();
    },

    onChangeNuSeRepartizeaza(){
      const vueInst=this;
      let p_NuSeRepartizeaza='';
      if(vueInst.nuSeRepartizeaza){ 
        p_NuSeRepartizeaza='y';
      }else{
        p_NuSeRepartizeaza='n';
      }
      ServiceDocument.nuSeRepartizeaza(Number(vueInst.appid), p_NuSeRepartizeaza).then((presponse)=>{ //vueInst.nuSeRepartizeaza ? 'y' : 'n'
        if(presponse.status=='success'){
          vueInst.$q.notify({
            color: 'teal',
            textColor: 'white',
            type: 'positive',
            message: presponse.message,
            position: 'top',
            timeout: 1000,
            html: true
          });
          
          if(p_NuSeRepartizeaza=='y'){
            vueInst.isRepartizat=true;            
          }
          // if (vueInst.nuSeRepartizeaza) {
          //   vueInst.isRepartizat = true;
          // }

          setTimeout(function(){ vueInst.closeWindow?.();}, 200);
        }
      });
    },

    getScans(){
      const vueInst=this;
      vueInst.uploadingFileToServer=true;
      ServiceDocument.getScans(vueInst.appid).then((presponse)=>{
        vueInst.loadingDocumentData=false;
        vueInst.uploadingFileToServer=false;
        if(presponse.status==='success'){
          vueInst.docApp.scans=JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    getRepartizari(){
      const vueInst=this;
      vueInst.uploadingFileToServer=true;
      ServiceRepartizari.getRepartizari(vueInst.appid, vueInst.docApp.id_registru as number).then((presponse)=>{
        vueInst.loadingDocumentData=false;
        vueInst.loadingRepartizari=false;
        if(presponse.status==='success'){
          vueInst.repartizari=JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    tryUpdateNumeDenumire(scope:any){
      const vueInst=this;
      if(scope.value){
        ServiceDocument.updateDocument(vueInst.appid,'nume_denumire',scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            vueInst.nume_denumire = vueInst.docApp.nume_denumire;
            scope.set();
          }
        })
      }
    },

    tryUpdateCui(scope:any){
      const vueInst=this;
      if(scope.value || scope.value === ''){
        ServiceDocument.updateDocument(vueInst.appid,'cui',scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateEmail(scope:any){
      const vueInst=this;
      if(scope.value || scope.value === ''){
        ServiceDocument.updateDocument(vueInst.appid,'email_solicitant',scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateNumePrenumeReprezentant(scope:any){
      const vueInst=this;
      if(scope.value || scope.value === ''){
        ServiceDocument.updateDocument(vueInst.appid,'nume_prenume_reprezentant',scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateNrTelefonReprezentant(scope:any){
      const vueInst=this;
      if(scope.value || scope.value === ''){
        ServiceDocument.updateDocument(vueInst.appid,'nr_telefon_reprezentant',scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateTitlu(scope:any){
      const vueInst=this;
      if(scope.value){
        ServiceDocument.updateDocument(vueInst.appid,'titlu',scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateDescriere(scope:any){
      const vueInst=this;
      if(scope.value){
        ServiceDocument.updateDocument(vueInst.appid,'descriere',scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateNrFile(scope:any){
      const vueInst=this;
      if(!scope.value || scope.value){
        ServiceDocument.updateDocument(vueInst.appid,'nr_file',scope.value.toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    openFormUploadFile(){
      this.filesSelectedForUpload=[];
      this.uploadScanTitle='';
      this.dialogUploadFile=true;
    },

    openFormAddOrgUnitRepartizare(){
      this.auTrecut2SecundeDeLaUltimulSearch=true;
      this.textSearchOrgUnit='';
      this.optionsOrgUnit=[];
      this.getOptionsOrgUnit('');
      this.dialogAddOrgUnitRepartizare=true;
    },

    openFormAddUtilizatorRepartizare(){
      this.auTrecut2SecundeDeLaUltimulSearch=true;
      this.textSearchUtilizator='';
      this.optionsUtilizator=[];
      this.getOptionsUtilizator('');
      this.dialogAddUtilizatorRepartizare=true;
    },

    openHistoryDialog(){
      this.dialogHistoricRepartizare = true;
    },

    closeHistoricRepartizare(){
      this.dialogHistoricRepartizare = false;
    },

    askIfDeleteRepartizare(){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti repartizarea pentru documentul ${vueInst.docApp.id_doc}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceDocument.stergeRepartizarea(vueInst.appid).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });

            this.getDocument();
            setTimeout(function(){ vueInst.closeWindow?.();}, 200);
          }
        });
      })
    },

    openReferenceAppIdDoc() {
      const vueInst = this;
      vueInst.emitter.emit('eventToOpenDocument', {action:'openDocumentDialogReference', appid: vueInst.docApp.appid_referinta, with_finalize:'no', with_repartizare_buton:'no', accesFrom: vueInst.accesFrom,
        task: { task_id: 0, task_type: 'DOCUMENT', action_on_step: { action_on_step: '', action_approve: '', action_reject: '', action_state_rejected: '' }, is_action_visible: false}});
    },

    prepareCoverPdfForDownload(){
      const vueInst = this;
      if (!['APP_HR'].includes(APP_NAME)) {
        vueInst.emitter.emit('eventToPreviewDocumentPDF', { action: 'show_preview_file', appid: vueInst.docApp.appid });
      } else {
        vueInst.emitter.emit('eventToPreview', { action: 'show_preview_file', title: 'Coperta', appid: vueInst.docApp.appid, url_to_get_file: `${CONFIG_ENV.URL_DOCUMENT.pdf_cover}` });
      }
    },

    finalizeazaInregistrareDocument(){
      const vueInst=this;      
      ServiceDocument.finalizeazaInregistrareDocument(vueInst.appid).then(
        presult =>{
          if(presult.status=='success'){
            vueInst.isFinalizat=true;
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presult.message,
              position: 'top',
              timeout: 1000,
              html: true
            });

            if(vueInst.$router.currentRoute.value.name !== 'Dashboard'){
              vueInst.$router.push({name: 'Dashboard', params: { param: 'documente_repartizate' } });
            }else{
              setTimeout(function(){ vueInst.closeWindow?.();}, 200);
            }
          }
        }
      );
    },

    askIfRemoveScan(pscan:IScan){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti fisierul reprezentand ${pscan.description}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceDocument.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            /*vueInst.docApp.scans.find((scan,index)=>{
              if(scan.appid==pscan.appid){
                vueInst.docApp.scans.splice(index,1);
              }
            })*/
            this.getScans();
          }
        });
      })
    },

    getDenTipDocument(pIdTipDoc: number): string{
      const vueInst=this;
      let result = '' as string;
      const TipDoc = vueInst.nomenclatoareStore.NomTipDoc.find( tipdoc =>{
        return tipdoc.appid == pIdTipDoc;
      });
      if(TipDoc){
        result=TipDoc.den_lang_1;
      }
      return result;
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },

    myUploadFile() {
      const vueInst=this;

      if (vueInst.uploadScanTitle.length == 0) {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa completati titlu documentului!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      } else if (vueInst.checkIfFileIsSelected()) {
        vueInst.uploadingFileToServer=true;        
    
        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        )

        fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidDoc: vueInst.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token})
          .then((e: any) => {
            vueInst.dialogUploadFile=false;
            vueInst.getScans();
            if(e.target && e.target.response.status && e.target.response.status=='error'){
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            }else{
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
            }
          })
          .catch((e: any) => {
          })
      } else {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa selectati fisierul!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      }
    },

    myUploadMultipleFiles() {
      const vueInst=this
        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        )
        
      if(vueInst.filesSelectedForUpload.length > 0){
        vueInst.filesSelectedForUpload.forEach(file => {
            
        fileUpload.upload(file as File, { appidDoc: vueInst.appid, description: file.name, userid: vueInst.user.userid, token: vueInst.user.csrf_token})
          .then((e: any) => {
            if(e.target && e.target.response.status && e.target.response.status=='error'){
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            }else{
              vueInst.uploadingFileToServer=true;
              vueInst.filesSelectedForUpload = [];
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
            }
          })
          .catch((e: any) => {
          })
          .finally(() => {
            vueInst.getScans();
          })
        });
      }
    },

    prepareForUpload(event:any){
      this.filesSelectedForUpload = event.target.files[0];
    },

    onProgress(event:any) {
      // Handdle the progress
    },

    inputOrgRepartizare(pval:string){
      this.auTrecut2SecundeDeLaUltimulSearch=false;
      this.getOptionsOrgUnit(pval);
    },

    getOptionsOrgUnit(psearchText:string){
      const vueInst = this;
      /*ServiceSearch.optionsOrganizatii(psearchText).then(presponse=>{
        if(presponse.status=='success'){
          vueInst.optionsOrganizatii=presponse.rows;
          setTimeout(()=>{ vueInst.auTrecut2SecundeDeLaUltimulSearch=true;}, 1000);
        }
      });*/      
      //console.log(psearchText);
      vueInst.optionsOrgUnit=vueInst.nomenclatoareStore.NomOrgUnits.filter(porg=>porg.organization_unit_descriere?.toUpperCase().indexOf(psearchText.toString().toUpperCase())!==-1);
      setTimeout(()=>{ vueInst.auTrecut2SecundeDeLaUltimulSearch=true;}, 500);
    },

    removeOrgUnitRepartizare(pindex:number){
      this.listaOrgUnitPentruRepartizare.splice(pindex,1);
    },

    repartizeazaDocument(){
      const vueInst = this;
      ServiceDocument.repartizeaza(vueInst.appid, vueInst.listaOrgUnitPentruRepartizare, vueInst.listaUtilizatorPentruRepartizare)
        .then(presponse=>{
          if(presponse.status=='success'){
            vueInst.isRepartizat=true;
            vueInst.docApp.este_repartizat='y';
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });

            setTimeout(function(){ vueInst.closeWindow?.();}, 200);
          }
        });
    },

    askIfDeleteRepartizareOrgUnitUtilizator(repartizare: IRepartizare, isOrgUnit: boolean){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti repartizarea pentru documentul ${vueInst.docApp.id_doc} repartizat la: ${isOrgUnit ? repartizare.den_org_destinatar : repartizare.nume_prenume_user_destinatar}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceDocument.stergeRepartizareOrgUnitUtilizator(vueInst.appid, repartizare.appid).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });

            this.getDocument();
            setTimeout(function(){ vueInst.closeWindow?.();}, 200);
          }
        });
      })
    },

    selectOrgUnitFromOption(pOrgUnit: IOrgUnit){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirmare',
        message: 'Adaugati ' + pOrgUnit.organization_unit_descriere + ' in lista de repartizat?',
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {        
        vueInst.dialogAddOrgUnitRepartizare=false;
        vueInst.textSearchOrgUnit='';
        vueInst.optionsOrgUnit=[];
        if(vueInst.listaOrgUnitPentruRepartizare.find(item=>{
          return item.id == pOrgUnit.id;
        })){
          vueInst.$q.notify({
            color: 'teal',
            textColor: 'white',
            type: 'positive',
            message: 'Deja selectat!',
            position: 'top',
            timeout: 1000,
            html: true
          });
        }else{
          vueInst.showVisualEffectAddingOrgUnit=true;
          setTimeout(()=>{ vueInst.showVisualEffectAddingOrgUnit=false;
            vueInst.listaOrgUnitPentruRepartizare.push(pOrgUnit);
          }, 500);
        }
        vueInst.goToDivBtnRepartizeaza();
      });
    },

    goToDivBtnRepartizeaza(){
      const el: HTMLElement = document.getElementById('div_btn_repartizeaza')!;
      const target = getScrollTarget(el)
      const offset = el.offsetTop
      const duration = 300;
      //setScrollPosition(target, offset, duration);
      setVerticalScrollPosition(target, offset, duration);
    },

    inputUtilizatorRepartizare(pval:string){
      this.auTrecut2SecundeDeLaUltimulSearch=false;
      this.getOptionsUtilizator(pval);
    },

    getOptionsUtilizator(psearchText:string){
      const vueInst = this;
      /*ServiceSearch.optionsOrganizatii(psearchText).then(presponse=>{
        if(presponse.status=='success'){
          vueInst.optionsOrganizatii=presponse.rows;
          setTimeout(()=>{ vueInst.auTrecut2SecundeDeLaUltimulSearch=true;}, 1000);
        }
      });*/      
      //console.log(psearchText);
      vueInst.optionsUtilizator = vueInst.nomenclatoareStore.NomPersoaneUtilizatoare.filter(puser=>(puser.last_name?.toUpperCase().indexOf(psearchText.toString().toUpperCase())!==-1 || puser.first_name?.toUpperCase().indexOf(psearchText.toString().toUpperCase())!==-1) && puser.is_activ == 'y');
      setTimeout(()=>{ vueInst.auTrecut2SecundeDeLaUltimulSearch=true;}, 500);
    },

    removeUtilizatorRepartizare(pindex:number){
      this.listaUtilizatorPentruRepartizare.splice(pindex,1);
    },

    selectUtilizatorFromOption(pPersoana: IPersoana){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirmare',
        message: 'Adaugati ' + (pPersoana.last_name + ' ' + pPersoana.first_name) + ' in lista de repartizat?',
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {        
        vueInst.dialogAddUtilizatorRepartizare=false;
        vueInst.textSearchUtilizator='';
        vueInst.optionsUtilizator=[];
        if(vueInst.listaUtilizatorPentruRepartizare.find(item=>{
          return item.marca == pPersoana.marca;
        })){
          vueInst.$q.notify({
            color: 'teal',
            textColor: 'white',
            type: 'positive',
            message: 'Deja selectat!',
            position: 'top',
            timeout: 1000,
            html: true
          });
        }else{
          vueInst.showVisualEffectAddingUtilizator=true;
          setTimeout(()=>{ vueInst.showVisualEffectAddingUtilizator=false;
            vueInst.listaUtilizatorPentruRepartizare.push(pPersoana);
          }, 500);
        }
        vueInst.goToDivBtnRepartizeaza();
      });
    },
    
    displayData(data: string|null): string{      
      if(data){
        const day = moment(data.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    checkIfFileIsSelected(): boolean{
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    denOrgUnitFromOrg(pOrgId:number){
      return denOrgUnitFromOrg(pOrgId);
    },

    denPersoanaUtilizatorFromMarca(pOrgId:number){
      return denPersoanaUtilizatorFromMarca(pOrgId);
    },    
    
    validateEmail(pEmail:string): boolean{
      return validateEmail(pEmail) || pEmail === '';
    },

    validateCNP(pCnp: string):boolean{
      return validateCNP(pCnp);
    },

    validateRomanianCIF(pCui: string):boolean{
      return validateRomanianCIF(pCui);
    },
    
    validateCNPORCIF(CUIORCNP: string):boolean {
      const vueInst = this;
      if (vueInst.docApp.tip_solicitant === 'pf') {
        return (CUIORCNP.length > 0 && validateCNP(CUIORCNP||'')) || CUIORCNP === '';
      } else {
        return (CUIORCNP.length > 0 && validateRomanianCIF(CUIORCNP||'')) || CUIORCNP === '';
      }
    },

    displayDenumireTara(pIdTara: number) :string{
      const vueInst = this;
      let result = '';

      /*ServiceTari.getTari().then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.tari = JSON.parse(presponse.rows) as iTari[];          
        }
      })*/

      const Tara = vueInst.tari.find(tara => {
        return tara.id_tara == pIdTara;
      });
  
      if(Tara){
        result = Tara.denumire;        
        return result;
      }

      return result;
    },

    displayDenumireJudet(pIdTara: number, pIdJudet: number) :string{
      const vueInst = this;
      let result = '';

      /*ServiceJudete.getJudete(pIdTara).then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.judete = JSON.parse(presponse.rows) as iJudete[];
        }
      })*/    

      const Judet = vueInst.judete.find(judet => {
        return judet.id_tara == pIdTara && judet.id_judet == pIdJudet;
      });
  
      if(Judet){
        result = Judet.denumire;        
        return result;
      }

      return result;
    },

    displayDenumireLocalitate(pIdTara: number, pIdJudet: number, pIdLocalitate: number) :string{
      const vueInst = this;
      let result = '';

      /*ServiceLocalitati.getLocalitati(pIdTara, pIdJudet).then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.localitati = JSON.parse(presponse.rows) as iLocalitati[];
        }
      })*/      

      const Localitate = vueInst.localitati.find(localitate => {
        return localitate.id_tara == pIdTara && localitate.id_judet == pIdJudet && localitate.id_localitate == pIdLocalitate;
      });
  
      if(Localitate){
        result = Localitate.denumire;        
        return result;
      }

      return result;
    },

    onSaveAdresa() {
      const vueInst = this; 
      vueInst.myForm.validate().then((success: any) => {
        if (success) {             
          ServiceDocument.updateAdresaDocument(vueInst.docApp as IDocument).then((presponse) => {
            if ( presponse.status === 'success' ) {
              vueInst.visibleDialogEditAdresa = false;              
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: presponse.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
              vueInst.origPopup_IdTara = vueInst.docApp.id_tara;
              vueInst.origPopup_IdJudet = vueInst.docApp.id_judet;
              vueInst.origPopup_IdLocalitate = vueInst.docApp.id_localitate;
              vueInst.origPopup_Strada = vueInst.docApp.strada;
              vueInst.origPopup_Numar = vueInst.docApp.numar;
              vueInst.origPopup_CodPostal = vueInst.docApp.cod_postal;
              
              if (vueInst.docApp.id_tara != null && vueInst.docApp.id_judet && vueInst.docApp.id_localitate) {
                vueInst.docApp.adresa = `${vueInst.displayDenumireTara(vueInst.docApp.id_tara as number)}, ${vueInst.displayDenumireJudet(vueInst.docApp.id_tara as number, vueInst.docApp.id_judet as number)}, ${vueInst.displayDenumireLocalitate(vueInst.docApp.id_tara as number, vueInst.docApp.id_judet as number, vueInst.docApp.id_localitate as number)}, ${vueInst.docApp.strada == null ? '' : vueInst.docApp.strada + ', '}${vueInst.docApp.numar == null ? '' : vueInst.docApp.numar + ', '}${vueInst.docApp.cod_postal == null ? '' : vueInst.docApp.cod_postal}`;
              }
                           
              if (vueInst.docApp.id_tara != null) {
                vueInst.onValueChangeTara(vueInst.docApp.id_tara as number);
              }              
            }
          });
        } else {
          // oh no, user has filled in
          // at least one invalid value
        }
      })
    },

    onCancelAdresa() {       
      const vueInst = this;           
      vueInst.visibleDialogEditAdresa = false;
      vueInst.docApp.id_tara = vueInst.origPopup_IdTara;
      vueInst.docApp.id_judet = vueInst.origPopup_IdJudet;
      vueInst.docApp.id_localitate = vueInst.origPopup_IdLocalitate;
      vueInst.docApp.strada = vueInst.origPopup_Strada;
      vueInst.docApp.numar = vueInst.origPopup_Numar;
      vueInst.docApp.cod_postal = vueInst.origPopup_CodPostal;

      if (vueInst.docApp.id_tara != null) {
        vueInst.onValueChangeTara(vueInst.docApp.id_tara as number);
      }
    },

    getAdrese(){
      const vueInst = this;

      vueInst.origPopup_IdTara = vueInst.docApp.id_tara;
      vueInst.origPopup_IdJudet = vueInst.docApp.id_judet;
      vueInst.origPopup_IdLocalitate = vueInst.docApp.id_localitate;
      vueInst.origPopup_Strada = vueInst.docApp.strada;
      vueInst.origPopup_Numar = vueInst.docApp.numar;
      vueInst.origPopup_CodPostal = vueInst.docApp.cod_postal;

      ServiceTari.getTari().then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.tari = JSON.parse(presponse.rows) as ITari[];

          vueInst.tari.unshift({
            appid: 0,    
            id_tara: 0,
            cod_tara: '',
            denumire: ''
          });

          if (vueInst.docApp.id_tara != null) {
            vueInst.onValueChangeTara(vueInst.docApp.id_tara as number);
          }   
        }
      });
    },

    onValueChangeTara(idTara: number) {
      const vueInst = this;
          
      if (idTara != vueInst.origPopup_IdTara) {
        vueInst.docApp.id_judet = 0;
        vueInst.docApp.id_localitate = 0;
      }

      ServiceJudete.getJudete(idTara).then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.judete = JSON.parse(presponse.rows) as IJudete[];
  
          vueInst.judete.unshift({
            appid: 0, 
            id_judet: 0,   
            id_tara: 0,
            cod_judet: '',
            denumire: ''
          });
          
          vueInst.judeteNoFiltered = vueInst.judete;
          vueInst.localitati = [];
          vueInst.localitatiNoFiltered = vueInst.localitati;

          if (vueInst.docApp.id_judet != null) {
            vueInst.onValueChangeJudet(vueInst.docApp.id_judet as number);
          }
        }
      });
    },

    onValueChangeJudet(idJudet: number) {
      const vueInst = this; 
      if (idJudet != this.origPopup_IdJudet) {        
        vueInst.docApp.id_localitate = 0;
      }

      if (idJudet == null) {
        vueInst.localitati = vueInst.localitatiNoFiltered;
      } else {
        ServiceLocalitati.getLocalitati(vueInst.docApp.id_tara as number, idJudet).then((presponse)=>{      
          if (presponse.status === 'success'){
            vueInst.localitati = JSON.parse(presponse.rows) as ILocalitati[];

            //debugger; // eslint-disable-line no-debugger
            vueInst.localitati.unshift({
              appid: 0, 
              id_localitate: 0,             
              id_tara: 0,
              id_judet: 0,
              denumire: '',
              cod_postal: ''
            });

            vueInst.localitatiNoFiltered = vueInst.localitati;
            
            if (vueInst.docApp.id_tara != null && vueInst.docApp.id_judet && vueInst.docApp.id_localitate) {
              vueInst.onValueChangeLocalitate(vueInst.docApp.id_localitate as number);
              vueInst.docApp.adresa = `${vueInst.displayDenumireTara(vueInst.docApp.id_tara as number)}, ${vueInst.displayDenumireJudet(vueInst.docApp.id_tara as number, vueInst.docApp.id_judet as number)}, ${vueInst.displayDenumireLocalitate(vueInst.docApp.id_tara as number, vueInst.docApp.id_judet as number, vueInst.docApp.id_localitate as number)}, ${vueInst.docApp.strada == null ? '' : vueInst.docApp.strada + ', '}${vueInst.docApp.numar == null ? '' : vueInst.docApp.numar + ', '}${vueInst.docApp.cod_postal == null ? '' : vueInst.docApp.cod_postal}`;
            } 
          }
        }) 
      }      
    },

    filterJudet(value: string, update: Function) {                
      if (value === '' || value === null) {
        update(() => {
          this.judete = this.judeteNoFiltered;
        });
      } else {
        update(() => {
          const valueLowerCase = value.toLowerCase();          
          this.judete = this.judeteNoFiltered.filter(f => f.denumire.toLowerCase().startsWith(valueLowerCase));
        }); 
      }
    },

    filterLocalitate(value: string, update: Function) {                
      if (value === '' || value === null) {
        update(() => {
          this.localitati = this.localitatiNoFiltered;
        });
      } else {
        update(() => {
          const valueLowerCase = value.toLowerCase();          
          this.localitati = this.localitatiNoFiltered.filter(f => f.denumire.toLowerCase().startsWith(valueLowerCase));
        }); 
      }
    },

    onState(operator: string){
      switch(operator) {
        case 'Refresh':
          this.getDocument();
          break;
        case 'Close':
          this.resetDocument();
          this.closeWindow?.();
          break;
      }
    },

    onValueChangeLocalitate(idLocalitate: number) {      
      if (idLocalitate == null) {
        this.adrese = [] as IAdrese[];
      } else {
        ServiceAdrese.getAdreseFilter(this.docApp.id_tara as number, this.docApp.id_judet as number, idLocalitate).then((presponse)=>{      
          if (presponse.status === 'success'){
            this.adrese = JSON.parse(presponse.rows) as IAdrese[];
    
            this.adrese.unshift({
              appid: 0, 
              id_adresa: 0,                         
              id_tara: 0,
              id_judet: 0,
              id_localitate: 0,  
              strada: '',              
              numar: '',
              cod_postal: 0,
              geo_lat: 0,
              geo_long: 0,  
              track_user: 0,
              track_date: '',
              denumire_tara: '',
              denumire_judet: '',
              denumire_localitate: ''
            });

            this.adreseNoFiltered = this.adrese;   
          }
        }) 
      }      
    },

    filterAdresa(value: string, update: Function) {
      if (value === '' || value === null) {
        update(() => {
          this.adrese = this.adreseNoFiltered;
        });
      } else {
        update(() => {
          const valueLowerCase = value.toLowerCase();          
          this.adrese = this.adreseNoFiltered.filter(f => f.strada.toLowerCase().startsWith(valueLowerCase));
          this.inputAdresa = value;
        }); 
      }
    },

    setAdresaModel(value: string) {
      this.docApp.strada = value;
      this.inputAdresa = '';
      this.isSelectedAdresa = true;
    },

    onBlurAdresa() {
      if (this.isSelectedAdresa != true) {
        this.docApp.strada = this.inputAdresa;
      }
    },

    onShowPopup() {
      this.inputAdresa = '';
      this.isSelectedAdresa = false;
    },

    onCreateDocumentIesire() {
      const vueInst = this;
      
      localStorage.setItem('DocAppParent', JSON.stringify(vueInst.docApp))
      vueInst.$router.push({ name: 'IesireDocument' });
      setTimeout(function(){ vueInst.closeWindow?.();}, 200);
    },

    onCancellationDocument() {
      const vueInst = this;

      vueInst.description_cancellation = '';
      vueInst.dialogCancellationDocument = true;           
    },

    onSendCancellationDocument() {
      const vueInst = this;            
      vueInst.myFormCancellationDocument.validate().then((success: any) => {
        if (success) {     
          ServiceDocument.cancellationDocument(Number(vueInst.docApp.appid), vueInst.description_cancellation as string).then((response) => {
            if(response.status=='success'){
              this.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: response.message,
                position: 'top',
                timeout: 1000,
              });
              
              vueInst.dialogCancellationDocument = false;
              vueInst.onState?.('Refresh');
            } 
          })
        }
      }); 
    },

    onOpenMessage() {
      const vueInst = this;

      vueInst.dialogMessage = true;
      vueInst.messageText = '';
      vueInst.callGetMessage();      
    },

    scrollToBottom() {
      const vueInst = this;
      
      vueInst.$nextTick(() => {
        // @ts-ignore
        vueInst.$refs.chatScroll.setScrollPosition('vertical', vueInst.$refs.chatScroll.$el.scrollHeight * 10000, 1);
        }
      );
    },

    onSendMessage() {
      const vueInst = this;
      const appId = ((vueInst.docApp.tip_request === 'intrare' && vueInst.docApp.sursa === 'Portal') ? vueInst.docApp.appid : (vueInst.docApp.tip_request === 'iesire' && vueInst.docApp.sursa === 'Ghiseu') ? vueInst.docApp.appid_referinta : 0) || 0;

      if (appId > 0) {
        vueInst.messageProgress = true;
                
        try{ 
          if (['APP_CV', 'APP_HR'].includes(APP_NAME)) {
            const jsonToAppIdMessage = JSON.stringify({
              "odocs_document_id" : appId,
              "message" : vueInst.messageText
            });

            fetch(`${APP_CLASS?.portalapi}${APP_CLASS?.messageapikey}`, {
              method: 'POST',
              headers: { 
                'Accept' : 'application.json',
                'Content-Type' : 'application/json' 
              },
              body: jsonToAppIdMessage
            })
            .then((response) => response.json())
            .then((json) => {
              if (json.success !== true) {
                vueInst.messageProgress = false;

                Notify.create({
                  color: 'red',
                  textColor: 'white',
                  type: 'negative',
                  message: 'Probleme cu trimitere mesaje!',// + appId,
                  position: 'top',
                  timeout: 3500,
                  html: true
                });
              } else {
                vueInst.messageText = '';
                vueInst.callGetMessage();
                
                setTimeout(function(){              
                  vueInst.$nextTick(() => 
                    // @ts-ignore
                    vueInst.$refs.textInputMessage.focus()
                  );        
                }, 500);

                vueInst.messageProgress = false;
              }
            })
            .catch((err) => {
                Notify.create({
                  color: 'red',
                  textColor: 'white',
                  type: 'negative',
                  message: err.message === "Failed to fetch" ? "A apărut o eroare necunoscută. Nu răspunde serverul de la Portal! " : err.message,
                  position: 'top',
                  timeout: 3500,
                  html: true
                });

                vueInst.messageProgress = false;
            });
          } else if (['APP_CJCV'].includes(APP_NAME)) {
            const jsonToAppIdMessage = JSON.stringify({
              "external_document_id" : appId,
              "message" : vueInst.messageText
            });

            fetch(`${APP_CLASS?.portalapi}`, {
              method: 'POST',
              headers: { 
                'Secret' : `${APP_CLASS?.messageapikey}`,
                'Accept-Charset' : 'application/json',
                'Accept' : 'application.json',
                'Content-Type' : 'application/json' 
              },
              body: jsonToAppIdMessage
            })
            .then((response) => response.json())
            .then((json) => {
              if (json.success !== true) {
                vueInst.messageProgress = false;

                Notify.create({
                  color: 'red',
                  textColor: 'white',
                  type: 'negative',
                  message: 'Probleme cu trimitere mesaje!',// + appId,
                  position: 'top',
                  timeout: 3500,
                  html: true
                });
              } else {
                vueInst.messageText = '';
                vueInst.callGetMessage();
                
                setTimeout(function(){              
                  vueInst.$nextTick(() => 
                    // @ts-ignore
                    vueInst.$refs.textInputMessage.focus()
                  );        
                }, 500);

                vueInst.messageProgress = false;
              }
            })
            .catch((err) => {
                Notify.create({
                  color: 'red',
                  textColor: 'white',
                  type: 'negative',
                  message: err.message === "Failed to fetch" ? "A apărut o eroare necunoscută. Nu răspunde serverul de la Portal! " : err.message,
                  position: 'top',
                  timeout: 3500,
                  html: true
                });

                vueInst.messageProgress = false;
            });
          }
        } catch(err) {
          let message = "";
          if (err instanceof Error) {
            message = err.message;
          } else {
            message = "Eroare necunoscuta! " + err;
          }

          Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: message,
            position: 'top',
            timeout: 3500,
            html: true
          });

          vueInst.messageProgress = false;
        };
      }      
    },

    callGetMessage() {
      const vueInst = this;
      const appId = ((vueInst.docApp.tip_request === 'intrare' && vueInst.docApp.sursa === 'Portal') ? vueInst.docApp.appid : (vueInst.docApp.tip_request === 'iesire' && vueInst.docApp.sursa === 'Ghiseu') ? vueInst.docApp.appid_referinta : 0) || 0;
      
      if (appId > 0) {
        vueInst.messageProgress = true;

        try{
          if (['APP_CV', 'APP_HR'].includes(APP_NAME)) {
            fetch(`${APP_CLASS?.portalapi}/${appId}${APP_CLASS?.messageapikey}`, {
              method: "GET",
              headers: { 
                'Accept' : 'application.json',
                'Content-Type' : 'application/json' 
              }
            })
            .then((response) => response.json())
            .then((json) => {
              if (json.length > 0) {
                vueInst.portalMessages = json;
                vueInst.chatMessages = [] as IChatMessages[];
                
                vueInst.portalMessages.forEach(message => {
                  vueInst.chatMessages.push({
                    name: message.message_type?.toLowerCase() === 'admin' ? 'Odocs' : 'Portal',
                    text: message.message,
                    sent: message.message_type?.toLowerCase() === 'admin',
                    stamp: dateToStringDDMMYYYYHHMMSS(moment(moment(message.created_at).format('YYYY-MM-DD HH:mm:ss')))
                  });
                });
                setTimeout(() => {
                  vueInst.scrollToBottom();
                }, 350);
              }
              vueInst.messageProgress = false;            
            })
            .catch((err) => {
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: err.message === "Failed to fetch" ? "A apărut o eroare necunoscută. Nu răspunde serverul de la Portal!" : err.message,
                position: 'top',
                timeout: 3500,
                html: true
              });

              vueInst.messageProgress = false;
            });
          } else if (['APP_CJCV'].includes(APP_NAME)) {
            fetch(`${APP_CLASS?.portalapi}/${appId}`, {
              method: "GET",
              headers: {  
                'Secret' : `${APP_CLASS?.messageapikey}`,
                'Accept-Charset' : 'application/json',
                'Accept' : 'application.json',
                'Content-Type' : 'application/json' 
              }
            })
            .then((response) => response.json())
            .then((json) => {
              let dataJSON = JSON.parse(JSON.stringify(json));
              if (dataJSON.success == true) {
                vueInst.portalMessages = dataJSON.data;
                vueInst.chatMessages = [] as IChatMessages[];
                vueInst.portalMessages.forEach(message => {
                  vueInst.chatMessages.push({
                    name: message.message_type?.toLowerCase() === 'admin' ? 'Portal' : 'Odocs',
                    text: message.message,
                    sent: message.message_type?.toLowerCase() === 'admin',
                    stamp: dateToStringDDMMYYYYHHMMSS(moment(moment(message.created_at).format('YYYY-MM-DD HH:mm:ss')))
                  });
                });
                setTimeout(() => {
                  vueInst.scrollToBottom();
                }, 350);
              } else {
                Notify.create({
                  color: 'red',
                  textColor: 'white',
                  type: 'negative',
                  message: "A apărut o eroare necunoscută. Nu răspunde serverul de la Portal! " + dataJSON,
                  position: 'top',
                  timeout: 3500,
                  html: true
                });
              }
              vueInst.messageProgress = false;            
            })
            .catch((err) => {
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: err.message === "Failed to fetch" ? "A apărut o eroare necunoscută. Nu răspunde serverul de la Portal!" : err.message,
                position: 'top',
                timeout: 3500,
                html: true
              });

              vueInst.messageProgress = false;
            });
          }
        } catch(err) {
          let message = "";
          if (err instanceof Error) {
            message = err.message;
          } else {
            message = "Eroare necunoscuta! " + err;
          }

          Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: message,
            position: 'top',
            timeout: 3500,
            html: true
          });

          vueInst.messageProgress = false;
          vueInst.dialogMessage = false;
        };
      }
    },

    getLinkToQrCode(appid: number): string{
      return `${CONFIG_ENV.URL_DOCUMENT.qr_code}/${appid}`;
    },

    openDialogDocumentReference() {
      const vueInst = this;

      vueInst.visibleDialogEditReferintaExtern = (vueInst.docApp.status !== 'archived' && 
        ((vueInst.user.is_admin=='y' || (vueInst.withFinalizeButton=='yes' && (vueInst.docApp.status === 'init' || vueInst.docApp.status === 'pending' || vueInst.docApp.status === 'inapproval') && vueInst.docApp.finalizat_de_inregistrat !== 'y')) && vueInst.docApp.appid_referinta == null));
    
      vueInst.ref_nr_doc = vueInst.docApp.ref_nr_doc;
      vueInst.ref_data_doc = vueInst.docApp.ref_data_doc == null ? '' : dateToStringDDMMYYYY(moment(vueInst.docApp.ref_data_doc.substring(0,10), 'YYYY-MM-DD'));
    },

    onSaveDocumentReference() {
      const vueInst = this; 
      const origRefNrDoc = vueInst.docApp.ref_nr_doc;
      const origRefDataDoc = vueInst.docApp.ref_data_doc;

      vueInst.myForm.validate().then((success: any) => {
        if (success) {           
          vueInst.docApp.ref_nr_doc = (vueInst.ref_nr_doc == null || vueInst.ref_nr_doc == '') ? null : vueInst.ref_nr_doc;
          vueInst.docApp.ref_data_doc = (vueInst.ref_data_doc == null || vueInst.ref_data_doc == '') ? null : dateToStringYYYYMMDDStandard(moment(vueInst.ref_data_doc.substring(0,10), 'DD.MM.YYYY'));

          ServiceDocument.updateReferenceDocument(vueInst.docApp as IDocument).then((presponse) => {
            if ( presponse.status === 'success' ) {                     
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: presponse.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
              
              vueInst.visibleDialogEditReferintaExtern = false;
              vueInst.getDocument();
            } else {
              vueInst.docApp.ref_nr_doc = origRefNrDoc;
              vueInst.docApp.ref_data_doc = origRefDataDoc;
            }
          });
        } else {
          // oh no, user has filled in
          // at least one invalid value
        }
      })
    },

    onCancelDocumentReference() {
      const vueInst = this;
      vueInst.visibleDialogEditReferintaExtern = false;
      vueInst.ref_nr_doc = null;
      vueInst.ref_data_doc = null;
    },

    filterOrgUnit(value: string, update: Function) {
      if (value === '' || value === null) {
        update(() => {
          this.orgUnits = this.orgUnitsNoFiltered;
        });
      } else {
        update(() => {
          const valueLowerCase = value.toLowerCase();          
          this.orgUnits = this.orgUnitsNoFiltered.filter(f => f.organization_unit_descriere.toLowerCase().includes(valueLowerCase));
          this.inputOrgUnit = value;
        }); 
      }
    },

    setOrgUnitModel(value: string) {
      this.nume_denumire = value;
      this.inputOrgUnit = '';
      this.isSelectedOrgUnit = true;
    },

    onBlurOrgUnit() {
      if (this.isSelectedOrgUnit != true) {
        this.nume_denumire = this.inputOrgUnit;
      }
    },

    onShowPopupOrgUnit() {
      this.inputOrgUnit = '';
      this.isSelectedOrgUnit = false;
    },

    onCancelDocumentDenumireDestinatar(scope:any) {
      const vueInst = this;      
      scope.cancel();
      vueInst.nume_denumire = vueInst.docApp.nume_denumire;
    },

    onSaveDocumentDenumireDestinatar(scope:any) {
      const vueInst=this;
      if((vueInst.nume_denumire as string)?.toString().length > 0 && vueInst.nume_denumire !== vueInst.docApp.nume_denumire){
        ServiceDocument.updateDocument(vueInst.appid,'nume_denumire',(vueInst.nume_denumire as string)?.toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            vueInst.docApp.nume_denumire = vueInst.nume_denumire;
            scope.set();
          }
        })
      }
    },

    onGenerateDocument() {
      const vueInst = this;

      if ((Number(vueInst.docApp.tip_doc_flag) & 2) != 0) {
        vueInst.visibleDialogEditFormCertificateUrbanism = true;
        vueInst.appid_documentextended = vueInst.docApp.appid_document_extended as number;        
      } else if ((Number(vueInst.docApp.tip_doc_flag) & 4) != 0) {
        vueInst.visibleDialogEditFormServiciiDeTransport = true;
        vueInst.appid_documentextended = vueInst.docApp.appid_document_extended as number;
      }      
    },

    onViewDocument() {
      const vueInst = this;

      vueInst.titleForPreviewForm = 'Vizualizare';
      vueInst.downloadingFile = true;
      let urlToGetFile = '';

      if ((Number(vueInst.docApp.tip_doc_flag) & 2) != 0) {
        vueInst.showPreviewFileCertificateUrbanism = true;
        urlToGetFile = `${CONFIG_ENV.URL_DOCUMENTEXTENDED.downloadScanForPreviewFromAppDocCertificateUrbanism}/${vueInst.docApp.appid_document_extended}`;
      } else if ((Number(vueInst.docApp.tip_doc_flag) & 4) != 0) {
        vueInst.showPreviewFileServiciiDeTransport = true;
        urlToGetFile = `${CONFIG_ENV.URL_DOCUMENTEXTENDED.downloadScanForPreviewFromAppDocServiciiDeTransport}/${vueInst.docApp.appid_document_extended}`;
      }
      
      if (urlToGetFile != '') {
        fetch(urlToGetFile, { 
          headers: 
            (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
              {
                odocsuserid: vueInst.userStore.User.userid.toString(),
                odocsusertoken: vueInst.userStore.User.csrf_token
              } : 
              {
                Odocsuserid: vueInst.userStore.User.userid.toString(),
                Odocsusertoken: vueInst.userStore.User.csrf_token
              }
        })
        .then(resp => resp.blob())
        .then(blob => {
          vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
          vueInst.isPDFFileType = blob.type == 'application/pdf';

          if (vueInst.isPDFFileType) {
            if (this.$q.platform.is.mobile) {
              setTimeout(function () {
                vueInst.downloadingFile = false;
                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
                if(divPreview){
                  divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
                }
              }, 500);
            } else {// is desktop
              setTimeout(function () {
                vueInst.downloadingFile = false;
                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
                if(divPreview){
                  divPreview.src = vueInst.urlBlobForPreviewFile; 
                }
              }, 500);
            }
          } else {
            blob.text().then(text => {
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: text,
                position: 'top',
                timeout: 3500,
                html: true
              });

              setTimeout(() => {
                vueInst.showPreviewFileCertificateUrbanism = false;
                vueInst.showPreviewFileServiciiDeTransport = false;
              }, 1000);
            });
          }
        })
        .catch((err) => {
            Notify.create({
              color: 'red',
              textColor: 'white',
              type: 'negative',
              message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
              position: 'top',
              timeout: 3500,
              html: true
            })
        });
      }
    },

    renuntEditareForm() {
      const vueInst = this;
      vueInst.visibleDialogEditFormCertificateUrbanism = false;
      vueInst.visibleDialogEditFormServiciiDeTransport = false;
      vueInst.appid_documentextended = 0;  
    },

    denStare(pStatus: string|null) {
      const vueInst=this;
      let result = '' as string;
      const Status = vueInst.nomenclatoareStore.StareGeneral.find(status =>{
        return status.status == pStatus;
      });
      if(Status){
        result=Status.label;
      }
      return result;
    }
  },

  computed: {
    APP_NAME(): string{
      return APP_NAME;
    },

    optionsRegistre(): INomRegistru[] {
      return JSON.parse(JSON.stringify(this.nomenclatoareStore.NomRegistre));
    },

    optionsCategorii(): INomCateg[] {
      return JSON.parse(JSON.stringify(this.nomenclatoareStore.NomCategorii));
    },

    optionsTipDoc(): INomTipDoc[] {
      return JSON.parse(JSON.stringify(this.nomenclatoareStore.NomTipDoc));
    },   

    user(): IUser{
      return this.userStore.User;
    },    

    optionsTari(): ITari[] {
      return this.tari;
    },

    optionsJudete(): IJudete[] {
      return this.judete;
    },
    
    optionsLocalitati(): ILocalitati[] {
      return this.localitati;
    },

    optionsAdrese(): IAdrese[] {
      return this.adrese;
    },

    optionsOrgUnitPopup(): IOrgUnit[] {
      return this.orgUnits;
    }
  },

  mounted() {
    const vueInst = this;

    vueInst.orgUnits = vueInst.nomenclatoareStore.NomOrgUnits;
    vueInst.orgUnitsNoFiltered = vueInst.orgUnits;
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {
        // console.log('onAppidChanged in WorkOnDocument');
        const vueInst = this;
        vueInst.resetDocument();
        //vueInst.scans = [];
        vueInst.repartizari = [];
        vueInst.listaOrgUnitPentruRepartizare = [];
        vueInst.loadingDocumentData = true;
        if (newVal != 0 && vueInst.appid != 0) {
          vueInst.isRepartizat=false;
          vueInst.isFinalizat=false;
          vueInst.getDocument();
          vueInst.loadingRepartizari = true;
        }        
      }
    },

    // timerCount: {
    //   handler(value) {
    //     const vueInst = this;
    //     if (value > 0 && vueInst.timerEnabled) {
    //       setTimeout(() => {
    //         vueInst.timerCount--;
    //         if (vueInst.timerCount == 0) {
    //           vueInst.timerEnabled = false;
    //         }
    //       }, 1000);
    //     } else {
    //       console.log(value, 'ELVILEG VEGE', vueInst.timerEnabled);
    //       setTimeout(function(){ 
    //         vueInst.timerCount = 5;
    //         vueInst.timerEnabled = true;
    //       }, 5000);
    //     }
    //   },
    //   immediate: true // This ensures the watcher is triggered upon creation
    // }
  }
});